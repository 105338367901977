import { GetStaticProps } from "next";
import Head from "next/head";
import { Seomatic } from "react-seomatic";

import { PLASMIC } from "@abnormal/plasmic-init-design-system";
import PageSections from "@components/PageSections";
import useSetHeaderTheme from "@hooks/useSetHeaderTheme";
import { PreviewContent } from "@interfaces/PreviewContent.interface";
import getCallToActions from "@lib/getCallToActions";
import craft from "@pages/api/craft";
import Query404 from "@queries/Query404.graphql";

//import Error from "next/error";

export const getStaticProps: GetStaticProps = async ({
	params,
	previewData: nextPreviewData,
	preview,
}) => {
	const previewData: PreviewContent = nextPreviewData as PreviewContent;

	const response = await craft(
		Query404,
		{
			drafts:
				!preview &&
        (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" ||
          process.env.VERCEL_ENV === "preview" ||
					process.env.NODE_ENV !== "production") &&
				null,
		},
		preview ? previewData.token : null
	);

	const { pageEntry, primaryNavigation, primaryCallToActions, persistentNav } =
		response;

	const callToActions = await getCallToActions(
		response.pageEntry?.allPageSections
	);

  const plasmicData = await PLASMIC.maybeFetchComponentData(["Footer"], {
    // deferChunks: true,
  });

	const returnResponse = {
		navigation: {
			primaryNavigation,
			primaryCallToActions,
		},
		pageEntry,
		callToActions,
		persistentNav,
		plasmicData,
	};

	return {
		props: returnResponse,
	};
};

export default function NotFound({ pageEntry, callToActions }): JSX.Element {
	useSetHeaderTheme(pageEntry.headerTheme);

	return (
		<>
			{/*<Error hasGetInitialPropsRun={false} statusCode={404} />*/}
			<Seomatic Head={Head} {...pageEntry.seomatic} />
			<PageSections
				sections={pageEntry.pageSections}
				callToActions={callToActions}
			/>
		</>
	);
}
